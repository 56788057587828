import React, { useEffect, useState } from "react"
import { Box, Text, Heading, Flex, Container } from "@theme-ui/components"
import CountUp from "react-countup"
import { useInView } from "react-intersection-observer"
import { GatsbyImage } from "gatsby-plugin-image"
import { Grid } from "theme-ui"

const NumbersCollection = ({ label, title, description, image, numbers }) => {
  return (
    <Box sx={{ py: [3, 3, 3, 0] }}>
      <Container>
        <Grid columns={[1, "1fr 1fr"]} gap={[0, 0, 6, 6]}>
          <Box>
            <Text variant="caption">{label}</Text>
            {title && (
              <Heading
                sx={{ pt: [3], pb: [4] }}
                as="div"
                variant="sectionTitle"
                dangerouslySetInnerHTML={{ __html: title }}
              />
            )}
            <Box sx={{ pb: [4] }}>
              <Text
                as="div"
                variant="sectionTitle"
                dangerouslySetInnerHTML={{ __html: description }}
              ></Text>
            </Box>
            <Flex>
              {numbers.map((number, index) => (
                <Box sx={{ mt: (index + 1) % 2 == 0 ? [5] : [0] }}>
                  <Number number={number} />
                </Box>
              ))}
            </Flex>
          </Box>
          <Box
            sx={{
              ".gatsby-image-wrapper": {
                height: "90%",
              },
            }}
          >
            {image && <GatsbyImage image={image.gatsbyImageData} alt="" />}
          </Box>
        </Grid>
      </Container>
    </Box>
  )
}

const Number = ({ number }) => {
  const [startCount, setStartCount] = useState(false)
  const [ref, inView] = useInView()
  useEffect(() => {
    if (inView === true && startCount === false) {
      setStartCount(true)
    }
  }, [inView])

  return (
    <Box sx={{ backgroundColor: "dark", p: [5] }} mr={4} mt={5} ref={ref}>
      <Heading
        sx={{
          fontSize: [6],
          textAlign: "center",
          marginTop: 0,
          marginBottom: 2,
        }}
      >
        <CountUp
          end={number.float}
          prefix={number.prefix ? `${number.prefix} ` : null}
          suffix={number.suffix ? ` ${number.suffix}` : null}
          start={startCount}
        />
      </Heading>
      <Text sx={{ color: "light", textAlign: "center" }} as="p">
        {number.legend}
      </Text>
    </Box>
  )
}

export default NumbersCollection
