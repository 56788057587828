import React from "react"
import { Box, Grid, Text, Container, Image, Flex } from "@theme-ui/components"
import { GatsbyImage } from "gatsby-plugin-image"

const CardCollection = ({ kicker, title, cards }) => {
  // Calcola il numero di colonne in base al numero di cards
  const getGridTemplateColumns = cardsLength => {
    if (cardsLength === 1) return "minmax(0, 400px)"
    if (cardsLength === 2) return "repeat(2, minmax(0, 400px))"
    return "repeat(3, 1fr)"
  }

  return (
    <Box>
      <Container>
        <Box>
          {kicker && (
            <Text
              variant="caption"
              sx={{
                display: "block",
                mb: 3,
                textTransform: "uppercase",
                color: "primary",
                textAlign: "center",
              }}
            >
              {kicker}
            </Text>
          )}
          {title && (
            <Text
              as="div"
              variant="sectionTitle"
              sx={{
                p: {
                  mx: "40px",
                  fontSize: ["14px", "14px", "20px"],
                  mt: "24px",
                  textAlign: "center",
                },
                em: {
                  color: "secondary",
                  fontStyle: "normal",
                },
                h2: {
                  textAlign: "center",
                  lineHeight: "1.4",
                  fontSize: [4, 5, 6],
                  fontWeight: "bold",
                },
              }}
              dangerouslySetInnerHTML={{ __html: title }}
            />
          )}
        </Box>

        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: [
              "minmax(0, 400px)",
              getGridTemplateColumns(cards.length),
              getGridTemplateColumns(cards.length),
            ],
            gap: [4, 4, "32px"],
            justifyContent: "center",
            pt: "68px",
            pb: "54px",
          }}
        >
          {cards.map((card, index) => (
            <Box
              key={index}
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                textAlign: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "stretch",
                  justifyContent: "center",
                  height: "200px",
                }}
              >
                <Image
                  src={card.image.url}
                  sx={{
                    objectFit: "contain",
                  }}
                />
              </Box>
              <Text
                as="h3"
                sx={{
                  fontSize: ["18px", "18px", "26px"],
                  lineHeight: "1.4",
                  fontWeight: "700",
                  textAlign: "center",
                }}
              >
                {card.caption}
              </Text>
            </Box>
          ))}
        </Box>
      </Container>
    </Box>
  )
}

export default CardCollection
