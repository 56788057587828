import React from "react"
import { Box, Container, Flex, Heading, Text, Grid } from "@theme-ui/components"

const TextAndDescription = ({ title, label, description, negativeMargin }) => {
  
  return (
    <Box sx={{ py: !negativeMargin && [3, 3, 3, 6], pb: [3, 3, 3, 4] }}>
      <Container>
        <Box>
          <Grid
            columns={["1fr", ".4fr .6fr"]}
            gap={[0, 0, 0, 6]}
            sx={{ backgroundColor: "dark", zIndex: "2", p: [3, 4, 6, 6] }}
          >
            <Box>
              <Text variant="caption">{label}</Text>
              <Heading
                as="div"
                variant="sectionTitle"
                sx={{
                  my: [2],
                  pb: [2, 2, 3, 7],
                  color: "light",
                  fontSize: [6],
                  fontWeight: "body",
                  letterSpacing: "-.5px",
                  lineHeight: "1.12",
                  em: {
                    fontStyle: "normal",
                    color: "primary",
                  },
                }}
                dangerouslySetInnerHTML={{ __html: title }}
              />
            </Box>
            <Text
              as="div"
              variant="p"
              sx={{ color: "light", py: [2] }}
              dangerouslySetInnerHTML={{ __html: description }}
            ></Text>
          </Grid>
        </Box>
      </Container>
    </Box>
  )
}

export default TextAndDescription
