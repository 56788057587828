import React, { useState } from "react"
import { Box, Container, Grid, Heading, Text, Flex } from "@theme-ui/components"
import { GatsbyImage } from "gatsby-plugin-image"
import {
  Accordion as AccordionWrapper,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from "react-accessible-accordion"
import { ArrowUp, ArrowDown, Download } from "react-feather"
import { i18nContext } from "../../context/i18nContext"
import { getColor } from "@theme-ui/color"
import themeUiTheme from "../../gatsby-plugin-theme-ui"
import * as Scroll from "react-scroll"
import { OutboundLink } from "../link"

const Accordion = ({ page, items, grouped }) => {
  const [selectedItem, setSelectedItem] = useState(0)

  const dark = getColor(themeUiTheme, "dark")
  const light = getColor(themeUiTheme, "light")
  const scroller = Scroll.scroller
  let LinkScrollTo = Scroll.Link

  const handleSelectScrollActivate = index => {
    scroller.scrollTo(index, {
      duration: 300,
      delay: 0,
      smooth: true,
      offset: -15, // Scrolls to element + 50 pixels down the page
    })
    setSelectedItem(index)
  }

  return (
    <Box sx={{ py: [3, 3, 3, 4] }}>
      <Container>
        {page.label && <Text variant="caption">{page.label}</Text>}
        <Grid
          columns={["1fr"]}
          gap={[0]}
          sx={{
            alignItems: "center",
            alignContent: "center",
            pt: [3, 3, 3, 3],
            width: "100%",
          }}
        >
          <AccordionWrapper
            preExpanded={[0]}
            onChange={e => handleSelectScrollActivate(e[0])}
            allowMultipleExpanded={false}
            allowZeroExpanded={true}
          >
            {grouped ? (
              <AccordionItem key={0} uuid={0} name={0}>
                <AccordionItemState>
                  {({ expanded }) => (
                    <>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          <Box
                            sx={{
                              px: [4],
                              pb: [3],
                              pt: [3],
                              cursor: "pointer",
                              backgroundColor: expanded ? "dark" : "gray",
                              "&:hover": {
                                backgroundColor: expanded ? "dark" : "gray",
                              },
                            }}
                          >
                            <Flex
                              sx={{
                                justifyContent: "space-between",
                                alignItems: "center",
                                position: "relative",
                              }}
                            >
                              <Box>
                                <Heading
                                  sx={{
                                    color: expanded ? "light" : "dark",
                                    fontWeight: "bold",
                                    m: 0,
                                  }}
                                  variant="h6"
                                >
                                  {items.map((item, index) => {
                                    if (items.length !== index + 1)
                                      return item.title + " / "
                                    else return item.title
                                  })}
                                </Heading>
                              </Box>

                              <Box
                                sx={{
                                  p: [2],
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  width: "48px",
                                  height: "48px",
                                  border: "2px solid",
                                  borderRadius: "1px",
                                  borderColor: expanded ? "light" : "dark",
                                }}
                              >
                                {expanded ? (
                                  <ArrowUp size="24" color={light} />
                                ) : (
                                  <ArrowDown size="24" color={dark} />
                                )}
                              </Box>
                            </Flex>
                          </Box>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <Box
                          sx={{
                            backgroundColor: expanded ? "light" : "gray",
                            py: [4],
                            width: "100%",
                          }}
                        >
                          <Grid columns={[1, items.length]}>
                            {items.map((item, index) => {
                              return (
                                <Box>
                                  <Heading
                                    sx={{
                                      fontWeight: "bold",
                                      m: [0],
                                      mb: [2],
                                    }}
                                    variant="h6"
                                  >
                                    {item.title}
                                  </Heading>
                                  <Text
                                    as="div"
                                    variant="p"
                                    sx={{
                                      color: expanded ? "dark" : "dark",
                                      p: {
                                        m: 0,
                                        letterSpacing: "2px",
                                        fontWeight: "bold!important",
                                      },
                                      table: {
                                        width: "100%",
                                        borderCollapse: "collapse",
                                        "tbody tr": {
                                          borderColor: "light",
                                          borderBottom: "1px solid",
                                          td: {
                                            p: [3],
                                          },
                                        },
                                      },
                                    }}
                                    dangerouslySetInnerHTML={{
                                      __html: item.description,
                                    }}
                                  />
                                </Box>
                              )
                            })}
                          </Grid>

                          {items[0].image.gatsbyImageData && (
                            <Box
                              sx={{
                                py: [6],
                                mb: 2,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <GatsbyImage
                                image={items[0].image.gatsbyImageData}
                                alt=""
                              />
                            </Box>
                          )}
                          <Text
                            as="div"
                            variant="p"
                            sx={{
                              pb: [4],
                              overflow: "auto",
                              color: expanded ? "dark" : "dark",
                              p: { m: 0, letterSpacing: "2px" },
                              width: "100%",
                              maxWidth: "90vw",
                              table: {
                                width: "100%",
                                borderCollapse: "collapse",
                                "tbody tr": {
                                  "&:first-child": {
                                    fontSize: items[0].image
                                      .gatsbyImageData && [2],
                                    fontWeight:
                                      items[0].image.gatsbyImageData && "bold",
                                  },
                                  "&:nth-child(2)": {
                                    fontSize: !items[0].image
                                      .gatsbyImageData && [2],
                                    fontWeight:
                                      !items[0].image.gatsbyImageData && "bold",
                                  },
                                  borderColor: "light",
                                  borderBottom: "1px solid",
                                  td: {
                                    "&:first-child": {
                                      fontSize: items[0].image
                                        .gatsbyImageData && [2],
                                      fontWeight:
                                        items[0].image.gatsbyImageData &&
                                        "bold",
                                    },
                                    "&:nth-child(2)": {
                                      fontSize: !items[0].image
                                        .gatsbyImageData && [2],
                                      fontWeight:
                                        !items[0].image.gatsbyImageData &&
                                        "bold",
                                    },
                                    p: [3],
                                  },
                                },
                              },
                            }}
                            dangerouslySetInnerHTML={{
                              __html: items[0].productTable,
                            }}
                          />
                          <i18nContext.Consumer>
                            {t => (
                              <Box sx={{ py: [4], maxWidth: "350px" }}>
                                <OutboundLink
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                  variant="buttons.full.primary"
                                  target="_blank"
                                  href={items[0].viewMore.documents[0].url}
                                >
                                  {t.downloadDataSheet} <Download />
                                </OutboundLink>
                              </Box>
                            )}
                          </i18nContext.Consumer>
                        </Box>
                      </AccordionItemPanel>
                    </>
                  )}
                </AccordionItemState>
              </AccordionItem>
            ) : (
              items.map((item, index) => (
                <Box
                  sx={{
                    py: [1],
                  }}
                >
                  <AccordionItem key={item.id} uuid={index} name={index}>
                    <AccordionItemState>
                      {({ expanded }) => (
                        <>
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              <Box
                                sx={{
                                  px: [4],
                                  pb: [3],
                                  pt: [3],
                                  cursor: "pointer",
                                  backgroundColor: expanded ? "dark" : "gray",
                                  "&:hover": {
                                    backgroundColor: expanded ? "dark" : "gray",
                                  },
                                }}
                              >
                                <Flex
                                  sx={{
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    position: "relative",
                                  }}
                                >
                                  <Box>
                                    <Heading
                                      sx={{
                                        color: expanded ? "light" : "dark",
                                        fontWeight: "bold",
                                        m: 0,
                                      }}
                                      variant="h6"
                                    >
                                      {item.title}
                                    </Heading>
                                  </Box>

                                  <Box
                                    sx={{
                                      p: [2],
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      width: "48px",
                                      height: "48px",
                                      border: "2px solid",
                                      borderRadius: "1px",
                                      borderColor: expanded ? "light" : "dark",
                                    }}
                                  >
                                    {expanded ? (
                                      <ArrowUp size="24" color={light} />
                                    ) : (
                                      <ArrowDown size="24" color={dark} />
                                    )}
                                  </Box>
                                </Flex>
                              </Box>
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <Box
                              sx={{
                                backgroundColor: expanded ? "light" : "gray",
                                py: [4],
                              }}
                            >
                              <Text
                                as="div"
                                variant="p"
                                sx={{
                                  color: expanded ? "dark" : "dark",
                                  p: {
                                    m: 0,
                                    letterSpacing: "2px",
                                    span: { fontWeight: "bold!important" },
                                  },
                                  table: {
                                    width: "100%",
                                    borderCollapse: "collapse",
                                    "tbody tr": {
                                      borderColor: "light",
                                      borderBottom: "1px solid",
                                      td: {
                                        p: [3],
                                      },
                                    },
                                  },
                                }}
                                dangerouslySetInnerHTML={{
                                  __html: item.description,
                                }}
                              />
                              <Text
                                as="div"
                                variant="p"
                                sx={{
                                  pb: [4],
                                  overflow: "auto",
                                  color: expanded ? "dark" : "dark",
                                  p: { m: 0, letterSpacing: "2px" },
                                  width: "100%",
                                  maxWidth: "90vw",
                                  table: {
                                    width: "100%",
                                    borderCollapse: "collapse",
                                    "tbody tr": {
                                      "&:nth-child(2)": {
                                        fontSize: [2],
                                        fontWeight: "bold",
                                      },
                                      borderColor: "light",
                                      borderBottom: "1px solid",
                                      td: {
                                        "&:first-child": {
                                          fontSize: [2],
                                          fontWeight: "bold",
                                        },
                                        p: [3],
                                        img: {
                                          width: "100%",
                                        },
                                      },
                                    },
                                  },
                                }}
                                dangerouslySetInnerHTML={{
                                  __html: item.productTable,
                                }}
                              />
                              <i18nContext.Consumer>
                                {t => (
                                  <Box sx={{ py: [4], maxWidth: "350px" }}>
                                    <OutboundLink
                                      sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                      }}
                                      variant="buttons.full.primary"
                                      target="_blank"
                                      href={item.viewMore.documents[0].url}
                                    >
                                      {t.downloadDataSheet} <Download />
                                    </OutboundLink>
                                  </Box>
                                )}
                              </i18nContext.Consumer>
                            </Box>
                          </AccordionItemPanel>
                        </>
                      )}
                    </AccordionItemState>
                  </AccordionItem>
                </Box>
              ))
            )}
          </AccordionWrapper>
        </Grid>
      </Container>
    </Box>
  )
}

export default Accordion
