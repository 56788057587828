import React from "react"
import { Box, Flex, Grid, Heading, Text, Container } from "@theme-ui/components"
import RichContentStructuredText from "../richContentStructuredText"
import { GatsbyImage } from "gatsby-plugin-image"
import { number } from "prop-types"

const ImageAndText = ({
  title,
  description,
  image,
  label,
  subtitle,
  rightAligned,
}) => {
  return (
    <Box sx={{ py: [3, 3, 3, 6] }}>
      <Box
        sx={{
          position: "relative",
          backgroundColor: "primary",
        }}
      >
        {image && (
          <Box
            sx={{
              background: "rgb(0,0,0)",
              background:
                "linear-gradient(to bottom, rgba(255,255,255,1), rgba(255,255,255,1) 50%, rgba(255,255,255,1) 75%, rgba(255,255,255,0) 75%);",
              textAlign: "center",
              ".gatsby-image-wrapper": {
                height: "100%",
              },
            }}
          >
            <GatsbyImage image={image.gatsbyImageData} alt="" />
          </Box>
        )}

        <Container>
          <Grid columns={[1, "1fr 1fr"]} gap={[4]}>
            <Box
              sx={{
                borderRight: ["none", "2px solid"],
                borderColor: ["", "light"],
                my: [2, 5, 5, 5],
              }}
            >
              <Text variant="caption.black">{label}</Text>
              <Heading
                as="div"
                variant="sectionTitleBlack"
                sx={{
                  my: [0, 2, 2, 2],
                  color: "light",
                  fontSize: [6],
                  fontWeight: "body",
                  letterSpacing: "-.5px",
                  lineHeight: "1.12",
                  em: {
                    fontStyle: "normal",
                    color: "primary",
                  },
                }}
                dangerouslySetInnerHTML={{ __html: title }}
              />
            </Box>
            <Box sx={{ py: [2, 2, 2, 5] }}>
              <Box>
                <Text
                  sx={{ p: [3, 3, 4, 6], display:"block", py: 2 }}
                  variant="sectionTitleBlack"
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              </Box>
            </Box>
          </Grid>
        </Container>
      </Box>
    </Box>
  )
}

export default ImageAndText
