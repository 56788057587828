import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { Box, Text, Container } from "@theme-ui/components"

const GenericText = ({ text }) => {
  // console.log(image)
  return (
    <Container>
        <Box dangerouslySetInnerHTML={{__html : text }} />
    </Container>
  )
}

export default GenericText
