import React from "react"
import { graphql } from "gatsby"
import { Box, Text, Grid, Container } from "@theme-ui/components"
import Layout from "../components/layout"
import { getPagePath } from "../utils/path"
import { HelmetDatoCms } from "gatsby-source-datocms"
import Accordion from "../components/blocks/accordion"
import PageHero from "./pageHero"
import ImageAndText from "../components/blocks/imageAndText"
import NumbersCollection from "../components/blocks/numbersCollections"
import TextAndDescription from "../components/blocks/textAndDescription"
import BigImageWithTextAndList from "../components/blocks/BigImageWithTextAndList"
import GridParagraphAndIcon from "../components/blocks/GridParagraphAndIcon"
import ContactForm from "../components/blocks/contactForm"
import GenericText from "../components/blocks/genericText"
import { renderRule, StructuredText } from "react-datocms"
import CardCollection from "../components/blocks/cardCollection"
import BannerHome from "../components/blocks/bannerHome"
import { isHeading, isParagraph } from "datocms-structured-text-utils"
const Page = ({ data: { page } }) => {
  const i18nPaths = page._allSlugLocales.map(locale => {
    return {
      locale: locale.locale,
      value: getPagePath(page, locale.locale),
    }
  })
  return (
    <Layout locale={page.locale} i18nPaths={i18nPaths}>
      <HelmetDatoCms seo={page.seoMetaTags}>
        <html lang={page.locale} />
      </HelmetDatoCms>
      <PageHero page={page} image={page.heroImage} />
      {page.content.map(block => (
        <>
          {block.model.apiKey === "text_and_description" && (
            <Box
              as="section"
              key={block.id}
              sx={{
                mt: block.negativeMargin ? [-7] : "",
                zIndex: 2,
                position: "relative",
              }}
            >
              <TextAndDescription
                negativeMargin={block.negativeMargin}
                label={block.label}
                title={block.title}
                description={block.description}
              />
            </Box>
          )}
          <Box as="section" key={block.id}>
            {block.model.apiKey === "image_and_text" && (
              <ImageAndText
                centerimage={block.centerimage}
                title={block.title}
                images={block.image}
                label={block.label}
                body={block.description}
                subtitle={block.subtitle}
                paddingtop={block.paddingtop}
              />
            )}
            {block.model.apiKey === "grid_paragraph_and_icon" && (
              <GridParagraphAndIcon
                label={block.label}
                title={block.title}
                subtitle={block.subtitle}
                items={block.content}
                isHome={false}
              />
            )}
            {block.model.apiKey === "generic_text" && (
              <GenericText text={block.genericText}></GenericText>
            )}
            {block.model.apiKey === "big_image_with_text_and_list" && (
              <BigImageWithTextAndList
                title={block.title}
                label={block.label}
                description={block.description}
                image={block.imageBig}
              />
            )}

            {block.model.apiKey === "accordion" && (
              <Accordion
                items={block.content}
                page={block}
                grouped={block.grouped}
              />
            )}
            {block.model.apiKey === "numbers_collection" && (
              <NumbersCollection
                label={block.label}
                title={block.title}
                description={block.description}
                image={block.imageNumber}
                numbers={block.numbers}
              />
            )}
            {block.model.apiKey === "contact_form" && (
              <ContactForm
                title={block.title}
                subtitle={block.subtitle}
                description={block.description}
              />
            )}
            {block.model.apiKey === "card_collection" && (
              <CardCollection
                kicker={block.kicker}
                title={block.title}
                cards={block.cards}
              />
            )}
            {block.model.apiKey === "banner" && <BannerHome block={block} />}
          </Box>
        </>
      ))}
      <Container sx={{ mb: 6 }}>
        {page.body.value && (
          <Grid columns={[1]} gap={32}>
            <Box>
              <StructuredText
                data={page.body}
                customRules={[
                  renderRule(
                    isHeading,
                    ({ adapter: { renderNode }, node, children, key }) => {
                      return renderNode(
                        () => {
                          return (
                            <Text
                              as={`h${node.level}`}
                              variant={`h${node.level}`}
                              sx={{ mb: 3 }}
                            >
                              {children}
                            </Text>
                          )
                        },
                        { key },
                        children
                      )
                    }
                  ),
                  renderRule(
                    isParagraph,
                    ({ adapter: { renderNode }, children, key }) => {
                      return renderNode(
                        () => {
                          return (
                            <Text as="p" mb={3} variant="article">
                              {children}
                            </Text>
                          )
                        },
                        { key },
                        children
                      )
                    }
                  ),
                ]}
              />
            </Box>
          </Grid>
        )}
      </Container>
    </Layout>
  )
}

export default Page

export const query = graphql`
  query PageQuery($id: String!) {
    page: datoCmsPage(id: { eq: $id }) {
      ...PageDetails
      ...PageTreeParent
      ...AllSlugLocales
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      body {
        value
      }
      content {
        ... on DatoCmsBanner {
          model {
            apiKey
          }
          background {
            hex
          }
          id
          body
          bannerImage: image {
            gatsbyImageData(placeholder: BLURRED, forceBlurhash: false)
          }
          pageLink: link {
            ... on DatoCmsInternalLink {
              id: originalId
              anchor
              locale
              model {
                apiKey
              }

              link {
                ... on DatoCmsPage {
                  ...PageDetails
                  ...PageTreeParent
                  ...AllSlugLocales
                }
              }
            }
            ... on DatoCmsExternalLink {
              id: originalId
              anchor
              url
              model {
                apiKey
              }
            }
          }
        }
        ... on DatoCmsNumbersCollection {
          id
          title
          label
          description
          imageNumber: image {
            gatsbyImageData(placeholder: BLURRED, forceBlurhash: false)
          }
          numbers {
            legend
            float
            suffix
            prefix
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsCardCollection {
          id
          model {
            apiKey
          }
          kicker
          title
          cards {
            caption
            image {
              url
              gatsbyImageData
            }
          }
        }
        ... on DatoCmsContactForm {
          id
          title
          subtitle
          description
          model {
            apiKey
          }
        }
        ... on DatoCmsAccordion {
          id
          title
          label
          grouped
          content {
            id
            ... on DatoCmsProdotto {
              id
              description
              title
              subtitle
              productTable
              image {
                gatsbyImageData(placeholder: BLURRED, forceBlurhash: false)
              }
              viewMore {
                ... on DatoCmsInternalLink {
                  idLink: originalId
                  anchor
                  locale
                  model {
                    apiKey
                  }
                  link {
                    ... on DatoCmsPage {
                      ...PageDetails
                      ...PageTreeParent
                      ...AllSlugLocales
                    }
                  }
                }
                ... on DatoCmsDocument {
                  idDocument: originalId
                  documents {
                    url
                  }
                }
              }
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsGridParagraphAndIcon {
          id
          label
          title
          subtitle
          content {
            ... on DatoCmsRichContent {
              ...RichContent
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsTextAndDescription {
          id
          label
          title
          description
          negativeMargin
          model {
            apiKey
          }
        }
        ... on DatoCmsGenericText {
          id
          genericText
          model {
            apiKey
          }
        }
        ... on DatoCmsBigImageWithTextAndList {
          id
          label
          title
          description
          imageBig: image {
            gatsbyImageData(placeholder: BLURRED, forceBlurhash: false)
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsImageAndText {
          id
          centerimage
          paddingtop
          subtitle
          title
          description
          image {
            gatsbyImageData(placeholder: BLURRED, forceBlurhash: false)
          }
          model {
            apiKey
          }
        }
      }
    }
  }

  fragment RichContent on DatoCmsRichContent {
    title
    label
    subtitle
    image {
      url
      gatsbyImageData(width: 1480, placeholder: BLURRED, forceBlurhash: false)
    }
    body {
      blocks {
        __typename
        ... on DatoCmsImageGallery {
          id: originalId
        }
        ... on DatoCmsEmbed {
          id: originalId
          ...EmbedDetails
        }
        ... on DatoCmsNumbersGroup {
          id: originalId
          numbers {
            legend
            float
            suffix
            prefix
          }
        }
      }
      links {
        __typename
        ... on DatoCmsInternalLink {
          id: originalId
          anchor
          locale
          model {
            apiKey
          }
          link {
            ... on DatoCmsPage {
              ...PageDetails
              ...PageTreeParent
              ...AllSlugLocales
            }
          }
        }
      }
      value
    }
    model {
      apiKey
    }
  }

  fragment AllSlugLocales on DatoCmsPage {
    _allSlugLocales {
      value
      locale
    }
  }

  fragment PageDetails on DatoCmsPage {
    id
    locale
    title
    slug
    root
    model {
      apiKey
    }
    heroImage {
      gatsbyImageData(width: 1920, placeholder: NONE, forceBlurhash: false)
    }
  }

  fragment EmbedDetails on DatoCmsEmbed {
    title
    code
    fullWidth
    model {
      apiKey
    }
  }

  fragment PageTreeParent on DatoCmsPage {
    treeParent {
      id
      title
      slug
      root
      locale
      ...AllSlugLocales
      treeParent {
        id
        title
        slug
        root
        locale
        ...AllSlugLocales
      }
    }
  }
`
